<template>
  <router-link :to="'/player/playlist/' + playlist.id">
    <div class="o2-playlist">
      <h5>{{ playlist.snippet.title }}</h5>
      <img
        :src="playlist.snippet.thumbnails.medium.url"
        :alt="playlist.snippet.title"
      />
      <small>{{ playlist.contentDetails.itemCount }} Videos</small>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "Playlist",
  props: {
    playlist: Object,
  },
};
</script>

<style scoped>
div.o2-playlist {
  position: relative;
  margin: 1rem auto;
  border: 0.1rem solid white;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.2s linear;
  opacity: 0.8;
}

div.o2-playlist:hover {
  opacity: 1;
  transform: scale(1.02);
}

div.o2-playlist a {
  color: silver;
  text-decoration: none;
}

div.o2-playlist a:hover {
  color: white;
}

div.o2-playlist:hover h5 {
  opacity: 0;
}

div.o2-playlist:hover small {
  opacity: 1;
}

div.o2-playlist h5 {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 0.5rem;
  background: rgba(0, 0, 0, 0.5);
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  color: white;
  font-size: 0.9em;
  opacity: 1;
  transition: all 0.2s linear;
}

div.o2-playlist small {
  position: absolute;
  top: 0;
  right: 0;
  margin: auto;
  padding: 0.5rem;
  background: rgba(0, 0, 0, 0.5);
  border-bottom-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  color: silver;
  opacity: 0;
  transition: all 0.2s linear;
}

img {
  width: 100%;
  border-radius: 0.5rem;
}
</style>
