<template>
  <div class="row">
    <div
      class="col-xl-3 col-lg-4 col-sm-6 col-xs-12"
      :key="playlist.id"
      v-for="playlist in playlists"
    >
      <Playlist :playlist="playlist" />
    </div>
  </div>
</template>

<script>
import Playlist from "./Playlist";

export default {
  name: "Playlists",
  components: {
    Playlist
  },
  props: {
    playlists: Array
  }
};
</script>
