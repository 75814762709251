<template>
  <div class="o2-container">
    <PageTitle text="Playlists" />
    <Loader :isLoading="isLoading" />
    <Playlists :playlists="playlists" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import Loader from "@/components/layout/Loader";
import PageTitle from "@/components/layout/PageTitle";

import Playlists from "./Playlists";

export default {
  name: "PlaylistContainer",
  components: {
    Loader,
    PageTitle,
    Playlists
  },
  computed: {
    ...mapGetters(["isLoading", "playlists"])
  },
  methods: {
    ...mapActions(["setPlayLists"])
  },
  mounted() {
    if (this.playlists.length === 0) {
      this.setPlayLists();
    }
  }
};
</script>
